import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostMeta from "../components/postMeta"


const Post = props => {
  const {
    data: {
      wpgraphql: { post },
    },
  } = props
  const { title, content, author, categories, tags } = post


  return (
    <Layout>
      <SEO title={title} />
      <div className="post mb-6">
        <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
        <PostMeta post={post} />
        <ul className="post meta">
          <li>
            Author: <Link to={`/user/${author.slug}`}>{author.name}</Link>
          </li>
          <li>
            {` // `}
            Category:
            <ul>
              {categories.nodes.map(cat => (
                <li key={cat.id}>
                  <Link to={`/blog/category/${cat.slug}`}>{cat.name}</Link>
                </li>
              ))}
            </ul>
          </li>
          <li>
            {` // `}
            Tags:
            <ul>
              {tags.nodes.map(tag => (
                <li key={tag.id}>
                  <Link to={`/blog/tag/${tag.slug}`}>{tag.name}</Link>
                </li>
              ))}
            </ul>
          </li>
        </ul>
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>
    </Layout>
  )
}

export default Post

export const pageQuery = graphql`
  query GET_POST($id: ID!) {
    wpgraphql {
      post(id: $id) {
        title
        content
        uri
        postId
        date
        author {
          name
          slug
        }
        categories {
          nodes {
            id
            slug
            name
          }
        }
        tags {
          nodes {
            id
            slug
            name
          }
        }
      }
    }
  }
`
